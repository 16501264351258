// @flow

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media } from '../../utils/styled-utils';

type Props = {
  text: string,
  title: string,
  src: string,
  reverse?: boolean,
  scaleImg?: number,
  horizontal?: boolean,
};

const Element = styled.article`
  display: flex;
  align-items: center;

  ${({ horizontal }) => horizontal && css`
    flex-wrap: wrap;
  `};

  ${media.less1024} {
    flex-wrap: wrap;
  }
`;

const Figure = styled.figure`
  flex-basis: 400px;
  height: auto;
  flex-shrink: 0;
  margin: 0;
  order: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  ${media.less1024} {
    flex-basis: 200px;
    width: 100%;
    margin: 0 auto;
  }

  ${({ horizontal }) => horizontal && css`
    flex-basis: 80%;
    margin: 0 auto;
    height: auto;

    ${media.less1024} {
      flex-basis: 100%;
    }
  `};
`;

const Img = styled.img`
  width: 400px;
  margin: 0;

  ${({ scaleImg }) => scaleImg && css`
     width: calc(400px / ${scaleImg});
  `};

  ${media.less1024} {
    width: auto;

    ${({ scaleImg }) => (scaleImg && css`
      height: calc(400px / ${scaleImg * 2});
      width: 100%;
      max-width: 768px;
    `)};
  }

  ${({ horizontal }) => horizontal && css`
    width: 100%;

    ${media.less1024} {
      height: auto;
      width: 100%;
      max-width: 768px;
    }
  }
 `};
`;

const TextBlock = styled.div`
  order: 2;
  ${media.more1024} {
    order: ${({ reverse }) => (reverse ? 0 : 2)};
  }
  ${({ horizontal }) => horizontal && css`
    display: flex;

    ${media.less1024} {
      display: block;
    }
  `}
`;

const Title = styled.header`
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.48px;
  color: ${({ theme }) => theme.color.charcoalGrey};
  padding-bottom: 17px;

  ${({ horizontal }) => horizontal && css`
    width: calc(100% / 10 * 4);

    ${media.less1024} {
      width: auto;
    }
  `};

  ${media.less1024} {
    text-align: center;
    font-size: 24px;
    letter-spacing: 0.32px;
  }
`;

const Text = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.color.slateGrey};

  ${media.less1024} {
    font-size: 16px;
  }

  li {
    margin: 0;
  }

  ${({ horizontal }) => horizontal && css`
    width: calc(100% / 10 * 6);

    ${media.less1024} {
      width: 100%;
    }
  `};
`;

const WireguardPageFeature = ({ text, title, src, reverse, scaleImg, horizontal }: Props) => (
  <Element horizontal={horizontal}>
    { src &&
      <Figure horizontal={horizontal}>
        <Img
          alt={title}
          src={src}
          scaleImg={scaleImg}
          horizontal={horizontal}
        />
      </Figure>
    }
    <TextBlock reverse={reverse} horizontal={horizontal}>
      <Title horizontal={horizontal}>
        {title}
      </Title>
      <Text
        horizontal={horizontal}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </TextBlock>
  </Element>
);

WireguardPageFeature.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  scaleImg: PropTypes.number,
  reverse: PropTypes.bool,
  horizontal: PropTypes.bool,
};

WireguardPageFeature.defaultProps = {
  scaleImg: 1,
  reverse: false,
  horizontal: false,
};

export default WireguardPageFeature;
