import React from 'react';
import { withNamespaces } from 'react-i18next';
import Api from '../../utils/api';
import './WireguardPageBody.scss';
import NotAMemberComponent from '../NotAMemberComponent';
import WireguardPageFeature from './WireguardPageFeature';
import ListOfFeatures from "../AppicationsPage/ListOfFeatures";
import PageHeaderTitle from '../PageHeaderTitle';
import speedImg from '../../assets/img/wireguardPage/illustration_speed.svg';
import securityImg from '../../assets/img/wireguardPage/illustration_security.svg';
import locationImg from '../../assets/img/wireguardPage/location.svg';
import perfomanceImg from '../../assets/img/wireguardPage/perfomance.svg';
import serverImg from '../../assets/img/wireguardPage/server.svg';
import pcImg from '../../assets/img/wireguardPage/pc.svg';
import chatImg from '../../assets/img/wireguardPage/chat.svg';
import rev1 from '../../assets/img/servicePage/sprite-logo@2x.jpg';
import rev2 from '../../assets/img/servicePage/logo-transparent-bestvpn-1504258566215@2x.jpg';

class WireguardPageBody extends React.Component {
  constructor(props) {
    super(props);

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
  }

  render() {
    const { t, brand } = this.props;
    const ListOfFeaturesAll = withNamespaces()(({ t }) => (
      <ListOfFeatures cards={[
        { title: t('page_wireguard:card_1_title'), text: t('page_wireguard:card_1_text'), imgSrc: locationImg },
        { title: t('page_wireguard:card_2_title'), text: t('page_wireguard:card_2_text'), imgSrc: pcImg },
        { title: t('page_wireguard:card_3_title'), text: t('page_wireguard:card_3_text'), imgSrc: serverImg },
        { title: t('page_wireguard:card_4_title'), text: t('page_wireguard:card_4_text'), imgSrc: chatImg },
        { title: t('page_wireguard:card_5_title'), text: t('page_wireguard:card_5_text'), imgSrc: perfomanceImg },
        { title: t('page_wireguard:card_6_title'), text: t('page_wireguard:card_6_text'), imgSrc: '' }
      ]}
      />
    ));

    return (
      <div className='page-wireguard'>
        <PageHeaderTitle brandName={brand.name} title={t('page_wireguard:wireguard')} />
        <div className='
              wrapper-wireguard
              wrapper-wireguard_bg_gray
              wrapper-wireguard_borderBottom
        '>
          <div className='header-wireguard'>
            <div className='innerWrapper-wireguard'>
              <div className='header-wireguard__grid'>
                <div className='header-wireguard__text'>
                  <div
                    className='title-logo__image'
                    dangerouslySetInnerHTML={{ __html: t('page_wireguard:wireguard') }}
                  />
                  <h1 className='title-wireguard_h1'>
                    {t('page_wireguard:h1')}
                  </h1>
                  <div
                    className='text-wireguard'
                    dangerouslySetInnerHTML={{ __html: t('page_wireguard:text_1') }}
                  />
                  <NotAMemberComponent
                    brand={brand.name}
                    orderUrl={this.api.orderUrl()}
                    text={t('page_wireguard:get_anonine_wireguard')}
                    buttonText={t('page_wireguard:get_anonine')}
                    nomask
                    noBorder
                    noPadding
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <h1 className='title-wireguard_h1 title-wireguard_h1_benefits'>
          {t('page_wireguard:h1_benefits')}
        </h1>
        <div className='wireguard-text-description'>
          {t('page_wireguard:text_description_1')}
        </div>
        <div className='wrapper-wireguard wrapper-wireguard-benefits'>
          <div className='innerWrapper-wireguard innerWrapper-wireguard_mw_1024'>
            <WireguardPageFeature
              title={t('page_wireguard:benefits_title_1')}
              text={t('page_wireguard:benefits_text_1')}
              src={speedImg}
            />
          </div>
        </div>
        <div className='wrapper-wireguard wrapper-wireguard-benefits'>
          <div className='innerWrapper-wireguard innerWrapper-wireguard_mw_1024'>
            <WireguardPageFeature
              title={t('page_wireguard:benefits_title_2')}
              text={t('page_wireguard:benefits_text_2')}
              reverse
              src=''
            />
          </div>
        </div>
        <div className='wrapper-wireguard wrapper-wireguard-benefits last'>
          <div className='innerWrapper-wireguard innerWrapper-wireguard_mw_1024'>
            <WireguardPageFeature
              title={t('page_wireguard:benefits_title_3')}
              text={t('page_wireguard:benefits_text_3')}
              src={securityImg}
            />
          </div>
        </div>
        <div className='wrapper-wireguard wrapper-wireguard_bg_gray'>
          <div className='innerWrapper-wireguard'>
            <NotAMemberComponent
              brand={brand.name}
              orderUrl={this.api.orderUrl()}
              text={t('page_wireguard:not_a_member')}
              buttonText={t('page_wireguard:get_anonine')}
              nomask
              noBorder
              noPadding
            />
          </div>
        </div>
        <h1 className='title-wireguard_h1 title-wireguard_h1_benefits'>
          {t('page_wireguard:h1_anonine')}
        </h1>
        <div className='wireguard-text-description'>
          {t('page_wireguard:text_description_2')}
        </div>
        <div className='wrapper-wireguard wrapper-wireguard-feature'>
          <div className='
            innerWrapper-wireguard
            innerWrapper-wireguard-future
            innerWrapper-wireguard_mw_1024
          '>
            <ListOfFeaturesAll />
          </div>
        </div>
        <div>
          <div className='innerWrapper-wireguard'>
            <div className='reviews-wireguard'>
              <div className='review-wireguard'>
                <div className='text-wireguard'>
                  {t('page_wireguard:rev_1')}
                </div>
                <div className='review-wireguard__logo'>
                  <img src={rev1} alt='VPN Ranks' />
                </div>
              </div>
              <div className='review-wireguard'>
                <div className='text-wireguard'>
                  {t('page_wireguard:rev_2')}
                </div>
                <div className='review-wireguard__logo'>
                  <img src={rev2} alt='Best VPN' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotAMemberComponent
          brand={brand.name}
          text={t('page_wireguard:not_a_member')}
          buttonText={t('page_wireguard:get_anonine')}
          orderUrl={this.api.orderUrl()}
        />
      </div>
    );
  }
}

export default withNamespaces()(WireguardPageBody);

