import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../layouts';
import PageHeader from '../components/PageHeader';
import PageFooter from '../components/PageFooter';
import PageContent from '../styled/PageContent';
import ServiceBody from '../components/WireguardPage/WireguardPageBody';

class WireguardPage extends React.Component {
  render() {
    const { gatsbyUrl, pythonUrl, brand, affiliateUrl } = get(this.props, 'data.site.siteMetadata');
    const { location } = this.props;

    return (
      <Layout location={location}>
        <div>
          <PageHeader
            brand={brand}
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
          />
          <PageContent>
            <ServiceBody
              gatsbyUrl={gatsbyUrl}
              pythonUrl={pythonUrl}
              brand={brand}
            />
          </PageContent>
          <PageFooter
            brand={brand}
            location={location}
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
            affiliateUrl={affiliateUrl}
          />
        </div>
      </Layout>
    );
  }
}

const pageQuery = graphql`
  query WireguardPageQuery {
    site {
      siteMetadata {
        brand {
          name
          nameCom
          twitter
          facebook
        }
        title
        gatsbyUrl
        pythonUrl
        affiliateUrl
      }
    }
  }
`;

export default ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={data => <WireguardPage data={data} location={location} />}
  />
);

